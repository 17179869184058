import axios from "axios";
//  export const baseUrl = "http://18.132.64.204:8000/";
//  export const baseUrl = "http://13.40.119.219:8000/";
 export const baseUrl = "http://18.171.235.177/";
// export const baseUrl = "https://api.macandgray.com/";

const signupRequest = async (formdata) => {
  const response = await axios.post(`${baseUrl}auth/register/`, formdata);

  return response;
};

// affiliate
const traderAffiliateRefList = async (idToken, id) => {
  try {
    const config = {
      // params: {
      //   affiliate_id: id,
      // },
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.get(
      `${baseUrl}affiliate/admin/affiliate-referred-list/?affiliate_id=${id}`,
      config
    );

    return response.data;
  } catch (error) {
    console.error("Error in getting trader ref list", error);
    throw error;
  }
};

const createAffiliateCode = async (idToken, formData) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.post(
      `${baseUrl}affiliate/create-link/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in creating affiliate code", error);
    throw error;
  }
};

// Traders

const getAllTradersRequest = async (idToken, pageNumber) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.get(
      `${baseUrl}accounts/admin/trader-list/?page=${pageNumber}&page_size=20`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
const getTraderDetailsRequest = async (idToken, login_id) => {
  const config = {
    params: {
      account: login_id,
    },

    headers: { Authorization: `Bearer ${idToken}` },
  };
  let output;
  await axios
    .get(`${baseUrl}accounts/admin/account-details/`, config)
    .then((res) => {
      output = res.data;
    })
    .catch(function (error) {
      output = error;
    });
  return output;
};
const getTraderTransactionsRequest = async (idToken, email) => {
  try {
    const config = {
      params: {
        search: email,
      },
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.get(`${baseUrl}payment/history/`, config);

    return response.data;
  } catch (error) {
    console.error("Error in getting trader transaction history:", error);
    throw error;
  }
};

const getTraderAccountsRequest = async (idToken, email) => {
  try {
    const config = {
      params: {
        search: email,
      },
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.get(
      `${baseUrl}accounts/admin/trader-list/`,
      config
    );
    return response.data.results;
  } catch (error) {
    console.error("Error in getting trader accounts:", error);
    throw error;
  }
};

const getTraderOverviewRequest = async (idToken, login_id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.get(
      `${baseUrl}accounts/overview/${login_id}/`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
const getTraderMetricsRequest = async (idToken, login_id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    const response = await axios.get(
      `${baseUrl}accounts/v2/account-metrics/${login_id}/`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
//User

const getUserDetails = async (idToken) => {
  let config = {
    headers: { Authorization: `Bearer ${idToken}` },
  };
  const output = await axios
    .get(`${baseUrl}user/profile/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const updateUserDetailsRequest = async ({ formData, idToken }) => {
  let config = {
    headers: { Authorization: `Bearer ${idToken}` },
  };
  let output;
  await axios
    .patch(`${baseUrl}user/profile/`, formData, config)
    .then((res) => {
      output = res.data;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

const loadUser = async (idToken) => {
  let config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}auth/user/`, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
};
// for payment payout

const payoutRequest = async (idToken, body) => {
  let config = {
    headers: { Authorization: `Bearer ${idToken}` },
  };
  try {
    const res = await axios.post(
      `${baseUrl}payment/request/payout`,
      body,
      config
    );
    return res;
  } catch (err) {
    return err;
  }
};

export {
  createAffiliateCode,
  getAllTradersRequest,
  getTraderAccountsRequest,
  getTraderDetailsRequest,
  getTraderMetricsRequest,
  getTraderOverviewRequest,
  getTraderTransactionsRequest,
  getUserDetails,
  loadUser,
  payoutRequest,
  signupRequest,
  traderAffiliateRefList,
  updateUserDetailsRequest,
};
