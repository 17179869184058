import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { TabPanel } from "../../../components/Reusable/TabPanel";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { getChallengePricing } from "../../../store/reducers/payment";
import { useDispatch, useSelector } from "react-redux";
import { dollarUS } from "../../../utilities/formatter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const Cards = ({ data, setFooterValue }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleMouseOver = (data) => {
    setFooterValue(data);
  };
  const Items = () => (
    <Grid
      container
      spacing={2}
      // component={"ul"}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "20px",
        gap: "10px",
      }}
    >
      {data.leverage && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Leverage")}
            </Typography>
            <Typography
              sx={{ fontWeight: 700, display: "flex", fontSize: "16px" }}
            >
              {data && "1:" + data.leverage}
            </Typography>
          </div>
        </>
      )}

      {data.account_balance && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Account Balance")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${dollarUS(data.account_balance)}`}
            </Typography>
          </div>
        </>
      )}

      {data.profit_target && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Evaluation Phase 1", {
                days: data && data.minimum_days_phase_1,
              })}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.profit_target} %`}
            </Typography>
          </div>
        </>
      )}

      {data.profit_target_stage_1 && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Profit target stage 1")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.profit_target_stage_1} %`}
            </Typography>
          </div>
        </>
      )}

      {data.profit_target_stage_2 && (
        <>
          {" "}
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Profit target stage 2")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.profit_target_stage_2} %`}
            </Typography>
          </div>
        </>
      )}

      {data.max_loss && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Max loss")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.max_loss} %`}
            </Typography>
          </div>
        </>
      )}

      {data.max_loss_stage_1 && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Max loss stage 1")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.max_loss_stage_1} %`}
            </Typography>
          </div>
        </>
      )}
      {data.max_loss_stage_2 && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Max loss stage 2")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.max_loss_stage_2} %`}
            </Typography>
          </div>
        </>
      )}

      {data.max_daily_loss && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Max daily loss")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.max_daily_loss} %`}
            </Typography>
          </div>
        </>
      )}

      {data.max_daily_loss_stage_1 && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Max daily loss-stage-1")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.max_daily_loss_stage_1} %`}
            </Typography>
          </div>
        </>
      )}
      {data.max_daily_loss_stage_2 && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Max daily loss-stage-2")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.max_daily_loss_stage_2} %`}
            </Typography>
          </div>
        </>
      )}

      {/* <Grid item xs={6} component={"li"}> */}
      {data.profit_split && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              Profit Split
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && `${data.profit_split}%`}
            </Typography>
          </div>
        </>
      )}
      {data.minimum_trading_days && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              Minimum Trading Days
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && `${data.minimum_trading_days}`}
            </Typography>
          </div>
        </>
      )}
      {/* </Grid> */}
      {/* <Grid item xs={6} component={"li"}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {data && data.bi_weekly_profit_split ? t("yes") : t("no")}
        </Typography>
        <Typography
          sx={{ fontWeight: 500, fontSize: "16px", color: "#636363" }}
        >
          Bi Weekly Profit Split
        </Typography>
      </Grid> */}
      {/* <Grid item xs={6} component={"li"}>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {data && data.max_capital_growth ? t("yes") : t("no")}
        </Typography>
        <Typography
          sx={{ fontWeight: 500, fontSize: "16px", color: "#636363" }}
        >
          Max Capital growth
        </Typography>
      </Grid> */}
    </Grid>
  );
  const displayText = data.name.split(" ");

  return (
    <CardContent
      className="pricing-card"
      onMouseOver={() => handleMouseOver(data)}
    >
      <Typography className="challenge_name">{data.name}</Typography>
      <Typography
        fontSize="36px"
        fontWeight={900}
        style={{ marginBottom: "40px" }}
      >
        ${displayText[0] && displayText[0]}
      </Typography>
      {/* <Typography mb={1}>{displayText.slice(1).join(" ")}</Typography> */}
      {/* <Divider sx={{ borderColor: "#636363", marginBottom: "24px" }}></Divider> */}
      <Items />
      <CardActions className="fevalBtn">
        <Button
          onClick={() =>
            navigate(`/payments/funding-evaluation/checkout/${data.id}`)
          }
        >
          {t("pay")} {dollarUS(data.price)}
        </Button>
      </CardActions>
    </CardContent>
  );
};

const FundingEvaluation = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { plans, challenges } = useSelector((state) => state.payment.pricing);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getChallengePricing());
  }, [dispatch]);
  const [flooterValue, setFooterValue] = useState([]);

  return (
    <Box component="div">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} component="div">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{ borderBottom: "3px solid #131348" }}
        >
          {challenges &&
            challenges.map((item, index) => {
              if (true) {
                return <Tab label={item.name} key={item} />;
              }
            })}
        </Tabs>
      </Box>

      {challenges &&
        challenges.map((challenge, idx) => {
          return (
            <>
              {challenge?.challenges?.length >= 1 && (
                <>
                  <TabPanel
                    value={value}
                    index={idx}
                    component="div"
                    className="opt-tabpanel"
                    sx={{ width: "1300px" }}
                  >
                    <Splide
                      aria-label="My Favorite Images"
                      options={{
                        // type: "Loop",
                        autoplay: true,
                        perPage: 3,
                        perMove: 1,
                        width: "100%",
                        drag: "free",
                        gap: "10px",
                        pauseOnHover: false,
                        breakpoints: {
                          1550: { perPage: 3, gap: "1rem" },
                          1350: { perPage: 2, gap: "1rem" },
                          970: { perPage: 1, gap: "1rem" },
                        },
                      }}
                    >
                      {challenge?.challenges.map((item, index) => (
                        <SplideSlide>
                          <Cards data={item} setFooterValue={setFooterValue} />
                        </SplideSlide>
                      ))}
                    </Splide>
                  </TabPanel>
                </>
              )}
            </>
          );
        })}
      <div className="footer_tier">
        <div className="footer_first">
          {/* <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {flooterValue.trading_requirements || "NA %"}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {t("trading-req")}
            </Typography>
          </div> */}
          {/* <div style={{ width: "calc(100% / 3)" }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                wordBreak: "break-word",
              }}
            >
              {flooterValue.tradable_asset}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {t("trade-assets")}
            </Typography>
          </div> */}
          <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {flooterValue.trade_through_news ? t("yes") : t("No")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {t("trading-news")}
            </Typography>
          </div>
        </div>
        <div className="footer_second">
          <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("yes")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {/* Trading on Weekend */}
              {t("hold-over-wkend")}
            </Typography>
          </div>
          <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {flooterValue.eas ? t("yes") : t("no")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              EAs
            </Typography>
          </div>
          <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {flooterValue.Hedging_and_Stacking ? t("yes") : t("no")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {t("hedging-stacking")}
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FundingEvaluation;
